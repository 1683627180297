<template>
  <div data-cy="projectsList">
    <h2>Planned projects</h2>
    <p>{{ error }}</p>
    <div v-if="isLoading">Loading...</div>
    <div v-for="project in projectsStore.projects" :key="project.name">
      <h3>{{ project.name }}</h3>
      <div class="project-description">
        <div>{{ project.description }}</div>
        <div v-if="project.linkToDetails" class="project-details">
          <div class="google-doc-link">
            <a :href="project.linkToDetails">
              <img src="@/assets/icons/document-svgrepo-com.svg" /></a>
            <a :href="project.linkToDetails" target="_blank">Project details
            </a>
          </div>
          <div class="hint">
            Follow the link to the Google Doc where you can leave comments, questions, suggestions to help us discuss
            and
            plan the project better. You do not have to register with us for that.
          </div>
        </div>
      </div>
      <div class="reservation">
        <div>
          <div class="table-like">
            <div class="header">Date planned</div>
            <div>{{ formatMonthYear(project.plannedStartDate) }}</div>
          </div>
          <div class="table-like">
            <div class="header">Duration</div>
            <div>{{ formatDuration(project.plannedDuration) }}</div>
          </div>
          <div>
            <button v-if="hasMembers" class="purchase" @click="reserve(project)">I want to participate</button>
            <a v-else :href="'mailto:info@caimito.net?subject=Participate in project ' + project.name"
              class="button purchase">I
              want to participate</a>
          </div>
        </div>
        <div>
          <div class="table-like">
            <div class="header">Open to</div>
            <div>
              <span v-for="(  participantType, index  ) in   project.participantTypes  " :key="index">{{
                $t('project.participantType.' + participantType) }}<span
                  v-if="index < project.participantTypes.length - 1">,
                </span></span>
            </div>
          </div>
          <div class="table-like">
            <div class="header">Reservations</div>
            <div>{{ project.participationSpotsLeft }} of {{ project.maxNumberOfParticipants }} spots left</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.google-doc-link {
  margin-top: 1em;
}

.reservation {
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1em;
}

.table-like {
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 1em;
}

.table-like .header {
  font-weight: bold;
  white-space: nowrap;
}

.project-details {
  display: grid;
  grid-template-columns: 20em auto;
  margin-top: 1em;
}

.hint {
  font-size: smaller;
}
</style>

<script>
import { isEnabled, MEMBERS } from '@/features'
import {
  formatDuration as helperFormatDuration, formatMonthYear as helperFormatMonthYear,
} from '@/library'
import { useProjectsStore } from '@/stores/projects'
import { useMemberStore } from '@/stores/member'
import { ref } from 'vue'

export default {
  name: "ProjectList",

  setup () {
    const isLoading = ref(true)
    const hasMembers = ref(false)
    const projectsStore = useProjectsStore()
    const memberStore = useMemberStore()
    hasMembers.value = isEnabled(MEMBERS)

    projectsStore.getProjects().then(() => {
      isLoading.value = false
    })

    return {
      isLoading,
      hasMembers,
      projectsStore,
      error: projectsStore.error,
      member: memberStore.member,
    }
  },

  methods: {
    formatDuration (value) {
      return helperFormatDuration(value)
    },

    formatMonthYear (value) {
      return helperFormatMonthYear(value)
    },

    isAuthenticatedMember () {
      return false
    },

    reserve (project) {
      this.$router.push({ name: 'communityRegister', params: { id: project.id } })
    },
  }
}
</script>
