
<template>
  <div class="container two-columns">
    <div>
      <h1>Projects</h1>
      <p class=" fine-print">In case you are wondering why this part of our website isn't in the language you
        selected: the participants for these projects and events very likely come from different countries and speak
        different languages. So we figure that keeping all the important things in English makes it equally acessible for
        everyone and easier to maintain.</p>
      <p>We welcome contributions to our Ecosystem Restoration Project. Below you find planned projects and how you can
        participate in them. Details about each project are available in the form of a Google Docs document where you can
        ask questions and leave comments.</p>

      <ProjectList />
    </div>
    <div class="participation-box">
      <h2>Ways to participate</h2>
      <div v-if="hasMembers">
        <button data-cy="becomeMember" v-if="!isAuthenticatedMember()" class="purchase" @click="becomeMember()">Become a
          member</button>
      </div>
      <p>There are three ways to participate. As a <strong>volunteer</strong>, as a <strong>temporary
          employee</strong>, and also as a <strong>contractor</strong>. See below what each role entails. Spanish
        language skills are not required.</p>
      <dl>
        <dt>Volunteer</dt>
        <dd>As an unpaid volunteer you are going on a working vacation. We will provide you a great experience, you
          can
          learn new skills, and enjoy the company of likeminded people.
        </dd>
        <dd>We pick you up at the train station or airport, provide a decent place to sleep, cook, eat and relax and
          we also give you the groceries. The group of volunteers self organizes cooking and cleaning.</dd>
        <dd>You should be comfortable with light manual labor, have no disabilities or other special
          requirements.
        </dd>

        <dt>Short-term employee</dt>
        <dd>We can employ you temporarily for a finite duration - one month and beyond - as a farm hand under Spanish
          law. You will receive the same benefits as the volunteers and share the space with that group.
        </dd>
        <dd>Unlike volunteers you need to have demonstrated experience related to the project in a remote interview.
          You
          will also be involved in day to day operations while your focus is on the project.
        </dd>
        <dd>
          If you are interested in temporary employment, contact us with your resume attached at <a
            href="mailto:info@caimito.net?subject=Temporary employment">info@caimito.net</a>.
        </dd>

        <dt>Contractor</dt>
        <dd>As a contractor, you will need to provide us with a fixed-price contract for the project's completion and
          be
          a registered business with VAT ID able to invoice us.</dd>
        <dd>
          If you want to make us an offer, contact us at <a
            href="mailto:info@caimito.net?subject=Contractor">info@caimito.net</a>.
        </dd>
      </dl>
    </div>
  </div>
</template>

<style scoped>
P.fine-print {
  font-size: smaller;
}

DIV.two-columns {
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 1em;
}

.participation-box {
  font-size: smaller;
}

.participation-box DL {
  margin-left: 0;
}

.participation-box DD {
  margin-left: 1em;
}

UL.tabs {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

UL.tabs li {
  float: left;
}

UL.tabs li a {
  display: block;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  cursor: pointer;
}

UL.tabs li a:hover {
  background-color: #28b839;
}

UL.tabs LI.selected {
  background-color: rgb(132, 204, 22);
}
</style>

<script>
import { isEnabled, MEMBERS } from '@/features'
import ProjectList from './ProjectList.vue';
import member from '@/mixins/member'

export default {
  name: "Community",
  components: {
    ProjectList,
  },
  mixins: [member],

  setup () {
    const hasMembers = isEnabled(MEMBERS)

    return {
      hasMembers,
    }
  },

  methods: {
    becomeMember () {
      this.$router.push({ name: 'members' })
    }
  }
}
</script>
