import { defineStore } from 'pinia'
import { ref, getCurrentInstance } from 'vue'
import { axiosInstance } from '@/library'

export const useProjectsStore = defineStore('projects', () => {
  const projects = ref([])
  const error = ref(null)

  const getProjects = () => {
    const appInstance = getCurrentInstance()
    const memberPublicId = appInstance.appContext.config.globalProperties.$cookies.get('memberPublicId')
      console.log('getProjects memberPublicId', memberPublicId)
      // TODO filter projects by memberPublicId

    return axiosInstance.get(`${window.VUE_APP_GRANJA}/api/v1/community/projects`)
      .then(response => {
        if (response.status === 200) {
          projects.value = response.data

          if (projects.value.length === 0)
            error.value = "There are no projects available at the moment. Please check back later."
        }
      })
      .catch(() => {
        error.value = "An error occured."
      })
  }

  return {
    projects,
    error,
    getProjects
  }
})
